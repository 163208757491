@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
/* Light mode */
@media (prefers-color-scheme: light) {
  body {
    background-color: white;
    color: black;
  }
}
/* Dark mode */
@media (prefers-color-scheme: dark) {
  body {
    background-color: black;
    color: white;
  }
}
@media (min-width: 1200px) {
  .navbar .container {
    max-width: 1400px !important;
  }
}
.quiz-answer,
button:focus .txt {
  display: none;
}

button:focus .quiz-answer {
  display: block;
  color: black;
}

.section-landing {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("https://assets.fullschedule.com/images/99cent_fh_0064.jpg");
  opacity: 0.4;
  background-color: darkgrey;
}

.section-shaped .shape {
  z-index: 0 !important;
}

.bg-blue {
  background-color: #191d4a;
}

.bg-yellow {
  background-color: #9c9037;
}

.bg-green {
  background-color: #1ebb4a;
}

.bg-red {
  background-color: #a72626;
}

.hidden {
  display: none;
}

.success-icon {
  width: 40px;
  height: 40px;
}

.success-icons {
  width: 46px;
  height: 44px;
  background: url(/assets/img/icons2x.png) 0 0;
}

.flag-icon {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 100%;
  position: relative;
  display: inline-block;
  width: 2.7rem;
  line-height: 1em;
}

.flag-icon-en {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/0.8.2/flags/4x3/us.svg);
}

.flag-icon-es {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/0.8.2/flags/4x3/mx.svg);
}

.lang-menu {
  min-width: 2em !important;
}

button:focus {
  outline: none !important;
}

.each-page li {
  font-size: 1rem !important;
}

.links-white a {
  color: #fff5db;
  text-decoration: underline;
  font-weight: 600;
}
.links-white a:hover {
  color: #ffd609;
  text-decoration: underline;
}

.button-min {
  min-width: 135px;
}

.left-menu-block {
  margin-left: 250px;
}

.subscriber-feature {
  font-size: 0.7em;
  color: #ef9300;
}

.buy-processing {
  color: #0a4d9d !important;
  background-color: #ffd609 !important;
  font-size: 1.3em;
  text-transform: uppercase;
}

.border-5 {
  border: 5px #fbbf0a solid;
}

.funkyradio div {
  clear: both;
  overflow: hidden;
}
.funkyradio label {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #d1d3d4;
  font-weight: normal;
}
.funkyradio input[type=radio]:empty,
.funkyradio input[type=checkbox]:empty {
  display: none;
}
.funkyradio input[type=radio]:empty ~ label,
.funkyradio input[type=checkbox]:empty ~ label {
  position: relative;
  line-height: 1.5;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 3.25em;
  cursor: pointer;
  user-select: none;
}
.funkyradio input[type=radio]:empty ~ label:before,
.funkyradio input[type=checkbox]:empty ~ label:before {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  content: "";
  width: 2.5em;
  line-height: 1.5;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #d1d3d4;
  border-radius: 3px 0 0 3px;
}
.funkyradio input[type=radio]:hover:not(:checked) ~ label,
.funkyradio input[type=checkbox]:hover:not(:checked) ~ label {
  color: #888;
}
.funkyradio input[type=radio]:hover:not(:checked) ~ label:before,
.funkyradio input[type=checkbox]:hover:not(:checked) ~ label:before {
  content: "✔";
  text-indent: 0.9em;
  color: #c2c2c2;
}
.funkyradio input[type=radio]:checked ~ label,
.funkyradio input[type=checkbox]:checked ~ label {
  color: #111111;
  font-weight: 600;
  background-color: #ffd609;
}
.funkyradio input[type=radio]:checked ~ label:before,
.funkyradio input[type=checkbox]:checked ~ label:before {
  content: "✔";
  text-indent: 0.9em;
  color: #333;
  background-color: #ccc;
}
.funkyradio input[type=radio]:focus ~ label:before,
.funkyradio input[type=checkbox]:focus ~ label:before {
  box-shadow: 0 0 0 3px #999;
}
.funkyradio-default input[type=radio]:checked ~ label:before,
.funkyradio-default input[type=checkbox]:checked ~ label:before {
  color: #333;
  background-color: #ccc;
}
.funkyradio-primary input[type=radio]:checked ~ label:before,
.funkyradio-primary input[type=checkbox]:checked ~ label:before {
  color: #fff;
  background-color: #337ab7;
}
.funkyradio-yellow input[type=radio]:checked ~ label:before,
.funkyradio-yellow input[type=checkbox]:checked ~ label:before {
  color: #fff;
  background-color: #0062cc;
}
.funkyradio-success input[type=radio]:checked ~ label:before,
.funkyradio-success input[type=checkbox]:checked ~ label:before {
  color: #fff;
  background-color: #5cb85c;
}
.funkyradio-danger input[type=radio]:checked ~ label:before,
.funkyradio-danger input[type=checkbox]:checked ~ label:before {
  color: #fff;
  background-color: #d9534f;
}
.funkyradio-warning input[type=radio]:checked ~ label:before,
.funkyradio-warning input[type=checkbox]:checked ~ label:before {
  color: #fff;
  background-color: #f0ad4e;
}
.funkyradio-info input[type=radio]:checked ~ label:before,
.funkyradio-info input[type=checkbox]:checked ~ label:before {
  color: #fff;
  background-color: #5bc0de;
}