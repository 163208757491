.custom-tab-content {
  .tab-content {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    position: relative;
    transition: all 0.15s ease;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07) !important;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    border: 0.0625rem solid rgba(0, 0, 0, 0.05);
    background-color: #fff;
    background-clip: border-box;
    padding: 1.5rem;
    flex: 1 1 auto;
  }
  .nav-pills {
    padding: 1rem 0;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    .nav-item {
      text-align: center;
      flex: 1 1 auto;
    }
  }
}
.flex-column .nav-pills {
  flex-direction: column;
}
@media (min-width: 768px) {
  .flex-md-row .nav-pills {
    flex-direction: row !important;
  }
}
.nav-pills-circle .nav-pills .nav-item:not(:last-child) {
  padding-right: 1rem;
}
