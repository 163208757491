$paragraph-font-size: 1.1rem !default;

.custom-tokens {
  thead {
    color: #525f7f;
    background-color: #e9ecef;
    border-color: #dee2e6;
  }

  th {
    font-weight: bold;
    color: #525f7f;
  }

  td {
    color: #525f7f;
  }
}

.image {
  &.image-style-side {
    float: right;
    max-width: 50%;
  }

  img {
    //height: 100%;
    max-height: 600px;
    display: block;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  //clear: both;
  text-align: center;
  margin: 1em;

  &.figcaption {
    display: table-caption;
    caption-side: bottom;
    word-break: break-word;
    color: #333;
    background-color: #f7f7f7;
    padding: 0.6em;
    font-size: 0.75em;
    outline-offset: -1px;
  }
}

.btn-reinforcement {
  background-color: #f1e3b8;
  //border-color: darkblue;
  color: #1d1d1d !important;
}

//.btn-start {
//  width: 100% !important;
//  padding: 0.875rem 1rem;
//  font-size: 1rem;
//  line-height: 1.5;
//  border-radius: 0.3rem;
//}

figure.media {
  display: block !important;
}
