.progress-wrapper {
  position: relative;
  padding-top: 1.5rem;
}
.progress {
  height: 18px;
  margin-bottom: $spacer;
  overflow: hidden;
  border-radius: $border-radius-sm;
  background-color: $progress-bg;
  z-index: 1;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.15s ease;
}
.progress .sr-only {
  width: auto;
  height: 20px;
  margin: 0 0 0 30px;
  left: 0;
  clip: auto;
  line-height: 20px;
  font-size: 13px;
}

.progress-heading {
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 2px;
  padding: 0;
}
.progress-bar {
  box-shadow: none;
  border-radius: 0;
  height: auto;
}
.progress-info {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.progress-label {
  span {
    display: inline-block;
    color: $primary;
    font-size: 0.625rem;
    font-weight: 600;
    text-transform: uppercase;
    background: rgba($primary, 0.1);
    padding: 0.25rem 1rem;
    border-radius: 30px;
  }
}

.progress-percentage {
  text-align: right;
  span {
    display: inline-block;
    color: $gray-600;
    font-size: 0.875rem;
    font-weight: 600;
  }
}
